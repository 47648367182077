import {Button, Card, FormCheckbox, FormInput, FormRadio} from "pbc-apps-components";
import React, {useState} from "react";
import {ProductPicker} from "../ProductPicker/ProductPicker";
import ProductItem from "../ProductItem/ProductItem";

const Actions = ({formMethods}) => {
  const [openProductPicker, setOpenProductPicker] = useState(false)

  return (
    <Card cardTitle={"Actions"} autoHeight={true}>
      <FormRadio
        elements={[
          {
            value: 'autoAddProduct',
            name: 'actionType',
            label: 'Automatically add products'
          },
          {
            value: 'showPopUp',
            name: 'actionType',
            label: 'Show popup with products'
          }
        ]} />
      <p className='mb2'>{formMethods.watch("actionType") === 'autoAddProduct' ? 'Automatically add products' : 'Show popup with products'}</p>
      {
        formMethods.watch("products")?.length > 0 ?
          formMethods.watch("products").map(product => {
            return <ProductItem product={product} formMethods={formMethods} products={formMethods.watch("products")} productsRoute={"products"}/>
          })
          : null
      }
      <Button
        fullWidth={true}
        onClick={() => setOpenProductPicker(true)}
        content={formMethods.watch("products")?.length > 0 ? 'Change products' : 'Add products'}
        size="sm"
        variant={"btnDefault"}
      />
      <p className='mb2'></p>
      <ProductPicker
        open={openProductPicker}
        setOpenProductPicker={setOpenProductPicker}
        formMethods={formMethods}
        blockName={"actions"}
        selectMultiple={true}
      />
      {
        formMethods.watch("actionType") === 'showPopUp' ?
          <>
            <FormInput
              name="maxProductsToAdd"
              type="number"
              label="Max products to add"
              min={1}
              max={20}
            />
            <FormRadio
              elements={[
                {
                  value: 'cartReload',
                  name: 'cartAction',
                  label: 'Reload page'
                },
                {
                  value: 'showPopUp',
                  name: 'cartAction',
                  label: 'Show popup with products'
                }
              ]} />
          </>
          : null
      }

    </Card>
  )
}
export default Actions