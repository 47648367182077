import Help from "./Pages/Help"
import PricingPlans from "./Pages/PricingPlans";
import RulesList from "./Pages/RulesList";

const menuItems = [
    {
      id: "rules-list",
      title: "Rules",
      type: "item",
      url: "/rules-list",
      component: <RulesList />
    },
    {
      id: "pricing",
      title: "Pricing Plans",
      type: "item",
      url: "/pricing-plans",
      component: <PricingPlans/>
    },
    {
      id: "help",
      title: "Help",
      type: "item",
      url: "/help",
      component: <Help/>
    }
];

export default menuItems
