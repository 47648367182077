import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from "react-grid-system";
import getApi from "../Helpers/Api";
import PricingPlanItem from "../Components/PricingPlanItem/PricingPlanItem";
import { Header } from "pbc-apps-components";

const PricingPlans = () => {
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    getApi().getPricingPlans().then(response => {
      setPricingPlans(response.data.data);
    });
  }, []);

  return (
    <>
      <div className="pageContent">
        <Container>
          <Row>
            <Col offset={{lg: 1}} lg={10}>
              <Header pageTitle="Pricing Plans"/>
            </Col>
          </Row>
          <Row>
            <Col offset={{lg: 1}} lg={10}>
              <Row>
                {
                  pricingPlans.map((pricingPlan, index) => {
                    return <Col lg={12 / pricingPlans.length} key={index}>
                      <PricingPlanItem pricingPlanData={pricingPlan} />
                    </Col>
                  })
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PricingPlans;
