import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {Button, Card, Header, Loader, Modal, Table} from "pbc-apps-components";
import {useNavigate} from "@shopify/app-bridge-react";
import 'react-toastify/dist/ReactToastify.css';
import getApi from "../Helpers/Api";
import {toast, ToastContainer} from "react-toastify";

const RulesList = () => {
  const [data, setData] = useState([])
  const [checkboxUpdatingState, setCheckboxUpdatingState] = useState({})
  const [selectedRules, setSelectedRules] = useState([])
  const [progressPending, setProgressPending] = useState(false)
  const [currentFromKey, setCurrentFromKey] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [nextFormKey, setNextFormKey] = useState(false)
  const [clearSelectedRowsValue, setClearSelectedRowsValue] = useState(false)
  const navigate = useNavigate();

  function updateData() {
    setProgressPending(true)

    getApi().getRules({perPage: rowsPerPage, fromKey: currentFromKey}).then(
      response => response.data
    ).then(async response => {
      const newKey = response.data.LastEvaluatedKey !== undefined ? response.data.LastEvaluatedKey : false;
      setProgressPending(false)
      const data = await processRules(response.data.Items)
      setData(data)
      setNextFormKey(newKey)
    }).catch(() => {
      setProgressPending(false)
    })
  }

  function processRules(rules) {
    return Promise.all(rules.map((rule) => {
      return rule;
    }));
  }

  function onPageChange(fromKey) {
    setCurrentFromKey(fromKey)
  }

  function deleteRule() {
    if (progressPending) {
      return false;
    }

    setProgressPending(true)

    getApi().deleteRules(selectedRules.map(rule => ({SK: rule.SK, ruleId: rule.ruleId}))).then(() => {
      setSelectedRules([])
      setClearSelectedRowsValue(!clearSelectedRowsValue)
      setShowDeletePopup(false)
      setProgressPending(true)

      showNotification('success', 'Successfully deleted')
      updateData();
    }).catch(error => {
      console.log('Error while deleting rules');
      console.log(error)
      showNotification('error', 'An error occurred while deleting rules!')
    });
  }

  function modalDeleteClose() {
    setShowDeletePopup(false)
  }

  function selectEntities(items) {
    setSelectedRules(items.selectedRows)
  }

  function setCheckboxUpdating(SK, updating) {
    let checkboxUpdating = checkboxUpdatingState;
    checkboxUpdating[SK] = updating;

    setCheckboxUpdatingState(checkboxUpdating)
  }

  function changeRuleEnabled(value, SK, actionType) {
    setCheckboxUpdating(SK, true);
    getApi().changeRuleEnabled(SK, value, actionType)
      .then((data) => {
        if (data.data.errors) {
         return showNotification('error', 'The rule with the popup already exists.')
        }
        setRuleEnabled(SK, value);
        showNotification('success', 'Changed rule status!')
      })
      .catch(error => {
        console.log(error)
      })
      .finally (() => {
        setCheckboxUpdating(SK, false);
      })
  }

  function renderEnabledCheckbox(row) {
    return getCheckboxUpdating(row.SK)
      ? <Loader height="sm"/>
      : <div className="tagSwitcher">
        <input type="checkbox" id={"switcher-" + row.SK} name={"enabled-" + row.SK}
               checked={row.enabled !== undefined ? row.enabled : true}
               onChange={(event) => changeRuleEnabled(event.target.checked, row.SK, row.actionType)}/>
        <label htmlFor={"switcher-" + row.SK}/>
      </div>
  }

  function setRuleEnabled(SK, enabled) {
    let newData = data.map(row => {
      if (row.SK === SK) {
        row.enabled = enabled;
      }
      return row;
    });
    setData(newData)
  }

  function getCheckboxUpdating(SK) {
    return checkboxUpdatingState[SK] ? checkboxUpdatingState[SK] : false
  }

  function showNotification(type, text) {
    toast[type](text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  useEffect(() => {
    updateData();
  }, [rowsPerPage, currentFromKey])

  return (
    <div className="pageContent">
      <Container>
        <Row>
          <Col offset={{lg: 1}} lg={10}>
            <Header pageTitle="Rules"/>
          </Col>
        </Row>
        <Row>
          <Col offset={{lg: 1}} lg={10}>
            <Card autoHeight={true}>
              <div className="tableActions flex-end mb2">
                {selectedRules.length > 0 &&
                  <div className="mr1">
                    <Button
                      content="Delete"
                      variant="btnWhite"
                      buttonTextColor="#f46a6a"
                      isLoading={progressPending}
                      onClick={() => setShowDeletePopup(true)}
                    />
                  </div>
                }
                <Button variant="btnPrimary" content="Add new rule" onClick={() => navigate('/rules-list/new')}/>
              </div>
              <Table
                title=""
                selectableRows={true}
                selectableRowsHighlight={true}
                clearSelectedRows={clearSelectedRowsValue}
                progressPending={progressPending}
                onSelectedRowsChange={selectEntities}
                onChangePage={onPageChange}
                keyField="SK"
                paginationServer={true}
                onChangeRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                nextFromKey={nextFormKey}
                striped={true}
                noHeader={true}
                columns={[
                  {
                    name: 'Name',
                    cell: row => row.ruleName,
                  },
                  {
                    name: 'Status',
                    cell: row => renderEnabledCheckbox(row),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                  {
                    cell: row => <Button variant="btnDefault" content="Edit"
                                         onClick={() => navigate('/rules-list/' + window.btoa(row.SK))}/>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  }
                ]}
                data={data}
              />
            </Card>
          </Col>
        </Row>
        <ToastContainer/>
      </Container>
      <Modal
        onClose={modalDeleteClose}
        title={''}
        show={showDeletePopup}
        additionalClass="modalDelete"
        content={
          <div className="mlAuto mrAuto" style={{maxWidth: '300px'}}>
            <h3 className="textCenter mt0 mb1">
              {`You are going to delete the Rule${selectedRules.length > 1 ? "s" : ''}.`}
            </h3>
            <p className="textCenter mt0 mb3">This option is irreversible. Please confirm.</p>
            <Row>
              <Col xs={6}>
                <div className="textCenter">
                  <Button content="Cancel" onClick={() => {
                    setShowDeletePopup(false)
                  }}/>
                </div>
              </Col>
              <Col xs={6}>
                <div className="textCenter">
                  <Button content="Delete" variant="btnDanger" isLoading={progressPending}
                          onClick={deleteRule}/>
                </div>
              </Col>
            </Row>
          </div>
        }
        headerContent={<></>}
        footerContent={<></>}
      />
    </div>
  );
}

export default RulesList;
