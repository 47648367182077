import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PricingPlanItem.module.scss';
import { Button } from "pbc-apps-components";
import getApi from "../../Helpers/Api";
import {useNavigate} from "@shopify/app-bridge-react";

const PricingPlanItem = ({ pricingPlanData }) => {
  const [choosingProcess, setChoosingProcess] = useState(false);

  const navigate = useNavigate();

  const choosePricePlan = () => {
    getApi().setPricingPlan(pricingPlanData.id).then(response => {
      if (response.data.hasOwnProperty("error") && response.data.error) {
        console.error(response.data.error);
      } else {
        navigate(response.data.data.url)
      }
    }).catch(error => {
      console.error(error);
    });
  };

  let plan = pricingPlanData;

  let itemClasses = [styles.planItemWrapper];

  if (plan.isCurrent) {
    itemClasses = [...itemClasses, styles.currentPlan];
  }

  console.log(plan)

  return (
    <div className={itemClasses.join(" ")}>
      <div className={styles.planHeader}>
        <div className={styles.planName}>
          {plan.planName}
        </div>
        <div className={styles.planPrice}>
          ${plan?.annual ? plan.price / 12 : plan.price} <span>/month</span>
        </div>
      </div>
      <div className={styles.planOptions}>
        <ul>
          {plan.options.map((option, key) => (
            <li key={key}>
              <span />
              {option}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.planFooter}>
        {!plan.isCurrent ? plan.price === 0 ?
            <Button content="Free Plan" onClick={() => false} /> :
            <Button isLoading={choosingProcess} content="Choose Plan" variant="btnPrimary" onClick={choosePricePlan} /> :
          <Button content="Current Plan" onClick={() => false} />}
      </div>
    </div>
  );
};

PricingPlanItem.propTypes = {
  pricingPlanData: PropTypes.object.isRequired,
};

export default PricingPlanItem;
