// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewRuleFirstStep_iconBox__eNLQ2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #000000;
  border-radius: 5px;
  margin-bottom: 20px;
}

.NewRuleFirstStep_cardText__9baOT {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewRuleFirstStep/NewRuleFirstStep.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EAEA,yBAAA;EACA,kBAAA;EAEA,mBAAA;AAHF;;AAKA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAFF","sourcesContent":["@import '../../variables';\n\n.iconBox {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n  height: 40px;\n\n  background-color: #000000;\n  border-radius: 5px;\n\n  margin-bottom: 20px;\n}\n.cardText {\n  font-size: 15px;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBox": `NewRuleFirstStep_iconBox__eNLQ2`,
	"cardText": `NewRuleFirstStep_cardText__9baOT`
};
export default ___CSS_LOADER_EXPORT___;
