import React from "react";
import styles from './App.module.scss';
import Routes from "./Routes";
import "pbc-apps-components/styles.css"
import { Inspectlet } from "pbc-apps-components";
import createApp from "@shopify/app-bridge";
import {Provider} from '@shopify/app-bridge-react';
import getApi from "./Helpers/Api";

const urlParams = new URLSearchParams(window.location.search);
let host = urlParams.get('host');

const shopifyConfig = {
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  host: host,
}

const app = createApp(shopifyConfig);

try {
  getApi().setApp(app);
  Inspectlet.setInitialData({ hostOrigin: atob(host), localOrigin: app.localOrigin });
} catch (e) {
  console.error(e);
}

const App = (props) => {

  return (
    <Provider config={shopifyConfig} >
      <div className={styles.mainWrapper}>
        <div className={styles.pageWrapper}>
          <Routes />
        </div>
      </div>
    </Provider>
  )
}

export default App;
