// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreviewWidget_listItem__OpYVj {
  font-size: 12px;
}

.PreviewWidget_listItem__OpYVj:not(:last-child) {
  margin-bottom: 6px;
}

.PreviewWidget_blockBox__zzpd- {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.PreviewWidget_blockBox__zzpd- .PreviewWidget_title__SJFUj {
  font-weight: bold;
  margin-bottom: 20px;
}

.PreviewWidget_blockBox__zzpd-:not(:last-child) {
  border-bottom: 1px solid #7D7D7D;
}

.PreviewWidget_bold__xTXzN {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/PreviewWidget/PreviewWidget.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,oBAAA;EACA,mBAAA;AAGF;AAFE;EACE,iBAAA;EACA,mBAAA;AAIJ;;AADA;EACE,gCAAA;AAIF;;AAFA;EACE,iBAAA;AAKF","sourcesContent":[".listItem {\n  font-size: 12px;\n}\n.listItem:not(:last-child) {\n  margin-bottom: 6px;\n}\n.blockBox {\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n  .title {\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n}\n.blockBox:not(:last-child) {\n  border-bottom: 1px solid #7D7D7D\n}\n.bold {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `PreviewWidget_listItem__OpYVj`,
	"blockBox": `PreviewWidget_blockBox__zzpd-`,
	"title": `PreviewWidget_title__SJFUj`,
	"bold": `PreviewWidget_bold__xTXzN`
};
export default ___CSS_LOADER_EXPORT___;
