import {ResourcePicker} from "@shopify/app-bridge-react";
import React from "react";

export const ProductPicker = (props) => {
  const {open, setOpenProductPicker, blockName, selectMultiple= false} = props

  const saveProducts = (data) => {
    let selection = data.selection
    let productsData = selection.map(product => {
      let variantId = ''
      let variantTitle = ''
      let productId = product.id.replace('gid://shopify/Product/', '')

      if (product.variants.length > 0 && product.variants[0].title !== "Default Title") {
        variantId = product.variants[0].id.replace('gid://shopify/ProductVariant/', '')
        variantTitle = product.variants[0].displayName
      } else {
        variantId = product.variants[0].id.replace('gid://shopify/ProductVariant/', '')
      }

      return {
        id: productId,
        title: product.title,
        variantId: variantId,
        variantTitle: variantTitle,
        description: product.descriptionHtml,
        image: product.images[0].originalSrc,
        handle: product.handle
      }
    })

    if (blockName === "condition") {
      const products = [...props.formMethods.watch('condition.products'), ...productsData]
      props.formMethods.setValue('condition.products', products, {shouldDirty: true})
      console.log(props.formMethods.watch('condition.products'), 'products')
      setOpenProductPicker(false)
    } else if (blockName === "actions") {
      const products = [...props.formMethods.watch('products'), ...productsData]
      props.formMethods.setValue('products', products, {shouldDirty: true})
      setOpenProductPicker(false)
    }
  }

  return (
    <ResourcePicker
      open={open}
      resourceType="Product"
      showVariants={true}
      selectMultiple={selectMultiple}
      initialSelectionIds={[]}
      onSelection={(data) => saveProducts(data)}
      onCancel={() => setOpenProductPicker(false)}
    />
  )
}