import {Card} from "pbc-apps-components";
import React from "react";
import PreviewCondition from "./PreviewCondition";
import style from './PreviewWidget.module.scss'


const PreviewWidget = ({formMethods}) => {

  let ruleType = ''

  switch (formMethods.watch('ruleType')) {
    case 'basedCartAmount':
      ruleType = 'Cart total price'
      break
    case 'basedCartItemsCount':
      ruleType = 'Cart quantity'
      break
    case 'basedSpecificProducts':
      ruleType = 'Cart specific product'
      break
    case 'basedProductCombinations':
      ruleType = 'Cart products combination'
      break
  }

  return (
    <Card cardTitle={ruleType} autoHeight={true}>
      <PreviewCondition formMethods={formMethods}/>
      <div className={style.blockBox}>
        <p className={style.title}>Action:</p>
        <p className='mb2'>
          {formMethods.watch("actionType") === 'autoAddProduct' ? 'Automatically add products:' : 'Show popup with products:'}
        </p>
        {
          <ul>
            {formMethods.watch("products")?.map(product => {
              return <li className={style.listItem} key={product.id}>
                {
                  product.variantTitle !== '' ?
                    <>
                      -
                      <span className={style.bold}>{product.variantTitle}</span>
                    </>
                    :
                    <>
                      -
                      <span className={style.bold}>{product.title}</span>
                    </>
                }
              </li>
            })}
          </ul>
        }
      </div>
      <div className={style.blockBox}>
        <p className={style.title}>Pricing:</p>
        <ul>
          {
            formMethods.watch('discountAmount') ?
              <li className={style.listItem}>
                - <span
                className={style.bold}>{formMethods.watch('discountAmount')}{formMethods.watch('discountType')?.value === 'percent' ? '%' : '$'}</span> off
                on products
              </li>
              : null
          }
          {
            formMethods.watch('freeShipping') ?
              <li className={style.listItem}>- Free shipping </li>
              : null
          }
        </ul>
      </div>
    </Card>
  )
}

export default PreviewWidget