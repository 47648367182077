// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductItem_box__moxgj {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 20px;
}

.ProductItem_titleBox__EUNHv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ProductItem_titleBox__EUNHv h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ProductItem/ProductItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;AADE;EACE,aAAA;EACA,gBAAA;EACA,eAAA;AAGJ","sourcesContent":[".box {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  margin-bottom: 20px\n}\n.titleBox {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  & h3{\n    margin-top: 0;\n    margin-bottom: 0;\n    font-size: 12px\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `ProductItem_box__moxgj`,
	"titleBox": `ProductItem_titleBox__EUNHv`
};
export default ___CSS_LOADER_EXPORT___;
