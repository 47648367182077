import {Button, Card, FormCheckbox, FormInput} from "pbc-apps-components";
import React, {useState} from "react";
import {ProductPicker} from "../ProductPicker/ProductPicker";
import ProductItem from "../ProductItem/ProductItem";

const Conditions = ({ruleType, formMethods}) => {
  const [openProductPicker, setOpenProductPicker] = useState(false)

  return (
    <>
      {
        ruleType === 'basedCartAmount'
        &&
        <Card cardTitle={"Conditions"} autoHeight={true}>
          <FormInput
            name="condition.min"
            type="number"
            label="Cart amount"
            min={1}
            max={1000000}
          />
          <FormCheckbox
            name={'condition.upperLimit'}
            label={'Add upper limit'}
            disabled={false}
            value={true}
          />
          {
            formMethods.watch('condition.upperLimit') ?
              <FormInput
                name="condition.max"
                type="number"
                label="Top amount"
                min={1}
                max={1000000}
              />
              : null
          }

        </Card>
      }
      {
        ruleType === 'basedCartItemsCount'
        &&
        <Card cardTitle={"Conditions"} autoHeight={true}>
          <FormInput
            name="condition.min"
            type="number"
            label="Cart items count"
            min={1}
            max={1000000}
          />
          <FormCheckbox
            name={'condition.upperLimit'}
            label={'Add upper limit'}
            disabled={false}
            value={true}
          />
          {
            formMethods.watch('condition.upperLimit') ?
              <FormInput
                name="condition.max"
                type="number"
                label="Top amount"
                min={1}
                max={1000000}
              />
              : null
          }
        </Card>
      }
      {
        ruleType === 'basedSpecificProducts'
        &&
        <Card cardTitle={"Conditions"} autoHeight={true}>
          <p className='mb2'>Product in cart</p>
          {
            formMethods.watch("condition.products")?.length > 0 ?
              formMethods.watch("condition.products").map(product => {
               return <ProductItem product={product} formMethods={formMethods} products={formMethods.watch("condition.products")} productsRoute={"condition.products"}/>
              })
              : null
          }
          <Button
            fullWidth={true}
            onClick={() => setOpenProductPicker(true)}
            content={formMethods.watch("condition.products")?.length > 0 ? 'Change products' : 'Add product'}
            size="sm" variant={"btnDefault"}
          />
          <ProductPicker
            open={openProductPicker}
            setOpenProductPicker={setOpenProductPicker}
            formMethods={formMethods}
            blockName={"condition"}
          />
        </Card>
      }
      {
        ruleType === 'basedProductCombinations'
        &&
        <Card cardTitle={"Conditions"} autoHeight={true}>
          <p className='mb2'>Product in cart</p>
          {
            formMethods.watch("condition.products")?.length > 0 ?
              formMethods.watch("condition.products").map(product => {
                return <ProductItem product={product} formMethods={formMethods} products={formMethods.watch("condition.products")} productsRoute={"condition.products"}/>
              })
              : null
          }
          <Button
            fullWidth={true}
            onClick={() => setOpenProductPicker(true)}
            content={formMethods.watch("condition.products")?.length > 0 ? 'Change products' : 'Add products'}
            size="sm" variant={"btnDefault"}
          />
          <ProductPicker
            open={openProductPicker}
            setOpenProductPicker={setOpenProductPicker}
            formMethods={formMethods}
            blockName={"condition"}
            selectMultiple={true}
          />
        </Card>
      }
    </>
  )
}

export default Conditions