import React from 'react';
import {Container, Row, Col} from 'react-grid-system'
import {Card, Header} from "pbc-apps-components";
import {useNavigate} from "@shopify/app-bridge-react";
import liveChatIcon from "../assets/images/live-chat.svg";
import ruleCreateIcon from "../assets/images/ruleCreateIcon.svg";
import ticketIcon from "../assets/images/send-ticket.svg";
import faqsIcon from "../assets/images/faqs.svg";

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="pageContent">
      <Container>
        <Row>
          <Col offset={{lg: 1}} lg={10}>
            <Header pageTitle="Dashboard"/>
          </Col>
        </Row>
        <Row>
          <Col offset={{lg: 1}} lg={10}>
            <Card autoHeight={true}>
              <Row>
                <Col md={6}>
                  <button onClick={() => navigate('/rules-list/new')} className="dashboardItemAction">
                    <img src={ruleCreateIcon} alt="Create Rule"/>
                    <span>Create new rule</span>
                  </button>
                </Col>
                <Col md={6}>
                  <a href="https://polarbearcommerce.com/apps/gift-products/faqs" target="_blank" rel="noreferrer" className="dashboardItemAction">
                    <img src={faqsIcon} alt="Faqs"/>
                    <span>FAQs</span>
                  </a>
                </Col>
                <Col md={6}>
                  <button onClick={() => window.tidioChatApi.open()} className="dashboardItemAction">
                    <img src={liveChatIcon} alt="Chat with us"/>
                    <span>Chat with us</span>
                  </button>
                </Col>
                <Col md={6}>
                  <a href="mailto:info@polarbearcommerce.com" target="_blank" rel="noreferrer" className="dashboardItemAction">
                    <img src={ticketIcon} alt="Email us"/>
                    <span>Email us</span>
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
