import {Card, Form, FormCheckbox, FormInput, FormSelect, Header, Submit} from "pbc-apps-components";
import React, {useEffect, useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Col, Container, Row} from "react-grid-system";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import {useNavigate} from "@shopify/app-bridge-react";
import Conditions from "../FormBlocks/Conditions";
import PopUpSettings from "../FormBlocks/PopUpSettings";
import PricingRule from "../FormBlocks/PricingRule";
import Actions from "../FormBlocks/Actions";
import getApi from "../../Helpers/Api";
import {toast, ToastContainer} from "react-toastify";
import PreviewWidget from "../PreviewWidget/PreviewWidget";

const validateData = {
  // Rule base
  ruleName: yup.string().required("⚠️ Please provide rule name"),
  ruleType: yup.string().required("⚠️ Please provide rule step name"),
  // Conditions
  conditions: yup.object(),
  //Actions
  actionType: yup.string(),
  products: yup.array().required("⚠️ Please select products"),
  maxProductsToAdd: yup.number().required("⚠️ Please provide max product"),
  //Popup settings
  popUpTitle: yup.string().required("⚠️ Please provide popup title"),
  popUpTitleFontSize: yup.number(),
  popUpTitleTextColor: yup.string(),
  popUpSubtitle: yup.string().required("⚠️ Please provide popup subtitle"),
  popUpSubTitleFontSize: yup.number(),
  popUpSubTitleTextColor: yup.string(),
  popUpBtnLabel: yup.string().required("⚠️ Please provide popup add button text"),
  popUpBtnTextColor: yup.string(),
  popUpBtnBackgroundColor: yup.string(),
  popUpBtnBorderColor: yup.string(),
  popUpBtnBorderRadius: yup.number(),
  //PricingRules
  discountType: yup.object(),
  discountAmount: yup.number(),
  freeShipping: yup.bool()
}

const RuleForm = (props) => {
  const {ruleConfig, setStepName, stepName} = props;
  const [savingProcess, setSavingProcess] = useState(false)
  const navigate = useNavigate();

  const backButton = () => {
    const handleBack = () => {
      if (location && location.pathname === '/rules-list/new') {
        setStepName('')
      } else {
        navigate('/rules-list')
      }
    }

    return <Link style={{marginRight: "10px"}} onClick={handleBack}>
      <svg width="16" height="20" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.4 1L1 7M7.4 13L1 7M1 7H15" stroke="black" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
      </svg>
    </Link>
  }

  const saveRule = (formData) => {
    if (savingProcess) {
      return false
    }
    setSavingProcess(true);
    getApi().addRule(formData)
      .then(response => response.data)
      .then((response) => {
        formMethods.reset(response.data.rule.Item)
        setSavingProcess(false);
        showNotification('success', 'Your changes have been saved!' + response.data.enabledRuleMessage)
      })
      .catch(error => {
        console.log('Error while saving look');
        console.log(error)
        setSavingProcess(false);
      });
  }

  const validationRules = yup.object().shape(validateData).required("⚠️ Please add rule")

  const formMethods = useForm({
    resolver: yupResolver(validationRules),
    reValidateMode: "onSubmit",
  });

  const isFormChanged = formMethods.formState.isDirty

  function showNotification(type, text) {
    toast[type](text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  useEffect(() => {
    formMethods.reset(ruleConfig)
  }, [ruleConfig]);

  return (
    <React.Fragment>
      <Container style={{maxWidth: '1200px'}}>
        <Header pageTitle={stepName ? stepName : formMethods.watch('ruleName')}
                contentLeft={backButton()}
                hasActionButton={true}
                contentRight={<div style={{marginLeft: 'auto'}}>
                  <Submit
                    btnVariant={!isFormChanged ? "btnDisabled" : "btnPrimary"}
                    submitLabel="Save"
                    form="rule-form"
                    size="sm"
                    savingProcess={savingProcess}
                    disabled={!isFormChanged}
                  />
                </div>}
        />
        <Form
          submitLabel="Save"
          onSubmit={formFields => saveRule(formFields)}
          id="rule-form"
          formMethods={formMethods}
          customSubmit={true}
          onError={console.log}
        >
          <Row>
            <Col lg={7}>
              <Card cardTitle={"Rule name"} autoHeight={true}>
                <FormInput
                  name="ruleName"
                  type="text"
                  label="Name"
                  onKeyDown={() => formMethods.clearErrors('ruleName')}
                />
              </Card>
              <Conditions formMethods={formMethods} ruleType={ruleConfig.ruleType}/>
              <Actions formMethods={formMethods}/>
              {
                formMethods.watch('actionType') === 'showPopUp' ?
                  <PopUpSettings formMethods={formMethods}/>
                  : null
              }
              <PricingRule formMethods={formMethods}/>
            </Col>
            <Col lg={5}>
              <PreviewWidget formMethods={formMethods}/>
            </Col>
          </Row>
        </Form>
        <ToastContainer/>
      </Container>
    </React.Fragment>
  )
}

RuleForm.propTypes = {
  ruleConfig: PropTypes.object
}

export default RuleForm;
