// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rule_loaderWrapper__N0Wjo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Rule.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADF","sourcesContent":["@import \"../variables\";\n\n.loaderWrapper{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 90vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `Rule_loaderWrapper__N0Wjo`
};
export default ___CSS_LOADER_EXPORT___;
