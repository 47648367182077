import {createReducer} from "@reduxjs/toolkit";
import {setAppEnabled} from "../actions/appConfigActions";

const appConfigReducer = createReducer([], builder => {
    builder
        .addCase(setAppEnabled, (state, action) => {
            return {...state, setAppEnabled: action.payload}
        })
})

export default appConfigReducer