import {Col, Container, Row} from "react-grid-system";
import {Button, Card, Header} from "pbc-apps-components";
import specificProductsIcon from "../../assets/images/specificProductsIcon.svg"
import productCombinationsIcon from "../../assets/images/productCombinationsIcon.svg"
import cartIcon from "../../assets/images/cartIcon.svg"
import style from "./NewRuleFirstStep.module.scss"
import RuleForm from "../RuleForm/RuleForm";
import {useState} from "react";
import * as yup from "yup";

const conditions = {
  basedCartAmount: {
    min: 120,
    upperLimit: false,
    max: 200,
  },
  basedCartItemsCount: {
    min: 5,
    upperLimit: false,
    max: 10,
  },
  basedSpecificProducts: {
    products: []
  },
  basedProductCombinations: {
    products: []
  },
}

const defaultFormSettings = {
  // Rule base
  ruleId: false,
  ruleName: 'New rule',
  ruleType: '',
  // Conditions
  condition: {},
  //Actions
  actionType: 'autoAddProduct',
  products: [],
  maxProductsToAdd: '1',
  //Popup settings
  popUpTitle: 'Congratulations! You are eligible to choose free product',
  popUpTitleFontSize: 30,
  popUpTitleTextColor: '#000000',
  popUpSubtitle: 'Choose product',
  popUpSubTitleFontSize: 20,
  popUpSubTitleTextColor: '#000000',
  popUpBtnLabel: 'Add to cart',
  popUpBtnTextColor: '#000000',
  popUpBtnBackgroundColor: '#FFFFFF',
  popUpBtnBorderColor: '#000000',
  popUpBtnBorderRadius: 8,
  //PricingRules
  discountType: {value: 'percent', label: "Percentage"},
  discountAmount: 100,
  freeShipping: true
}

const NewRuleFirstStep = () => {
  const [ruleConfig, setRulesConfig] = useState({})
  const [stepName, setStepName] = useState('')

  function stepProcess(ruleType, name) {
    let newRuleConfig = {...defaultFormSettings, condition: conditions[ruleType], ruleType}
    setRulesConfig(newRuleConfig)
    setStepName(name)
  }

  return (
    <>
      {
        stepName === ''
          ?
          <Container style={{maxWidth: '1200px'}}>
            <Header pageTitle={'Select template'}
                    contentRight={false}
            />
            <Row>
              <Col lg={3}>
                <Card autoHeight={true}>
                  <div className={style.iconBox}>
                    <img src={cartIcon} alt="Cart icon"/>
                  </div>
                  <p className={style.cardText}>Based on cart amount</p>
                  <Button content="Go to settings"
                          onClick={() => stepProcess('basedCartAmount', 'Based on cart amount')} size="sm"
                          variant={"btnDefault"}/>
                </Card>
              </Col>
              <Col lg={3}>
                <Card autoHeight={true}>
                  <div className={style.iconBox}>
                    <img src={cartIcon} alt="Cart icon"/>
                  </div>
                  <p className={style.cardText}>Based on cart items count</p>
                  <Button content="Go to settings"
                          onClick={() => stepProcess('basedCartItemsCount', 'Based on cart items count')} size="sm"
                          variant={"btnDefault"}/>
                </Card>
              </Col>
              <Col lg={3}>
                <Card autoHeight={true}>
                  <div className={style.iconBox}>
                    <img src={specificProductsIcon} alt="Cart icon"/>
                  </div>
                  <p className={style.cardText}>Based on specific products</p>
                  <Button content="Go to settings"
                          onClick={() => stepProcess('basedSpecificProducts', 'Based on specific products')} size="sm"
                          variant={"btnDefault"}/>
                </Card>
              </Col>
              <Col lg={3}>
                <Card autoHeight={true}>
                  <div className={style.iconBox}>
                    <img src={productCombinationsIcon} alt="Cart icon"/>
                  </div>
                  <p className={style.cardText}>Based on product combinations</p>
                  <Button content="Go to settings"
                          onClick={() => stepProcess('basedProductCombinations', 'Based on product combinations')}
                          size="sm"
                          variant={"btnDefault"}/>
                </Card>
              </Col>
            </Row>
          </Container>
          :
          <RuleForm ruleConfig={ruleConfig} setStepName={setStepName} stepName={stepName}/>
      }
    </>
  )
}

export default NewRuleFirstStep