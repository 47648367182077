import axios from "axios";
import {getSessionToken} from "@shopify/app-bridge/utilities";

let instance = null

class Api {
  constructor() {
    if (!instance) {
      instance = this;
      this.client = axios.create({
        baseURL: "/" + process.env.REACT_APP_API_APPENDIX,
        timeout: 31000,
      });

      this.client.defaults.headers.common["Cache-Control"] = "no-cache";
    }

    return instance;
  }

  getSessionToken() {
    return getSessionToken(this.app).then(token => {
      return token;
    }).catch(error => {
      console.log("Error getting token");
      console.error(error);
    });
  }

  setApp(app) {
    this.app = app;
    this.getSessionToken().then(token => {
      this.client.defaults.headers.common["Authorization"] = "Bearer " + token;
    })
  }

  getApp() {
    return this.app
  }

  get(path, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig['headers']['Authorization'] = `Bearer ${token}`;
      return this.client.get(path, requestConfig).catch(error => {
        console.log('Error while calling ' + path);
        console.error(error);
      });
    })
  }

  post(path, data, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig.headers['Authorization'] = `Bearer ${token}`;
      return this.client.post(path, data, requestConfig).catch(error => {
        console.log('Error while calling ' + path);
        console.error(error);
      })
    });
  }
  patch(path, data, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig.headers['Authorization'] = `Bearer ${token}`;
      return this.client.patch(path, data, requestConfig).catch(error => {
        console.log('Error while calling ' + path);
        console.error(error);
      })
    });
  }

  addRule(data) {
    return this.post('rules', data)
  }

  delete(path, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig.headers['Authorization'] = `Bearer ${token}`;
      return this.client.delete(path, requestConfig).catch(error => {
        console.log('Error while deleting ' + path);
        console.error(error);
      })
    });
  }

  getAppConfig = () => {
    return this.get('/config');
  }

  sendHelpRequest(data) {
    return this.post('help-request', {
      data: data
    })
  }

  getPricingPlans() {
    return this.get('pricing-plans')
  }

  setAppAvailability(enabled) {
    return this.post('app-availability', {
      "app-enabled": enabled,
    })
  }

  getAppAvailability() {
    return this.get('app-availability').then(response => {
      return response.data.data.enabled;
    })
  }

  getRules(params) {
    return this.get('rules', {params: params})
  }

  getRule(SK) {
    return this.get('rules/' + SK)
  }
  deleteRules(data) {
    return this.patch('rules', data)
  }
  changeRuleEnabled(SK, enabled, actionType) {
    return this.post('rule-enabled', {
      SK: SK,
      enabled: enabled,
      actionType: actionType,
    })
  }
}

const getApi = () => {
  return new Api();
}

export default getApi
