import {Card, FormCheckbox, FormInput, FormInputColor} from "pbc-apps-components";
import React from "react";
import {Col, Row} from "react-grid-system";

const PopUpSettings = ({formMethods}) => {
  return (
    <Card cardTitle={"Popup settings"} autoHeight={true}>
      <FormInput
        name="popUpTitle"
        type="text"
        label="Name"
        onKeyDown={() => formMethods.clearErrors('popUpTitle')}
      />
      <Row>
        <Col>
          <FormInput
            name="popUpTitleFontSize"
            type="number"
            label="Font size, px"
            min={0}
            max={100}
          />
        </Col>
        <Col>
          <FormInputColor
            name="popUpTitleTextColor"
            type="text"
            label="Button background color"
          />
        </Col>
      </Row>
      <FormInput
        name="popUpSubtitle"
        type="text"
        label="Name"
        onKeyDown={() => formMethods.clearErrors('popUpSubtitle')}
      />
      <Row>
        <Col>
          <FormInput
            name="popUpSubTitleFontSize"
            type="number"
            label="Subtitle font size, px"
            min={0}
            max={100}
          />
        </Col>
        <Col>
          <FormInputColor
            name="popUpSubTitleTextColor"
            type="text"
            label="Subtitle text color"
          />
        </Col>
      </Row>
      <FormInput
        name="popUpBtnLabel"
        type="text"
        label="Name"
        onKeyDown={() => formMethods.clearErrors('popUpBtnLabel')}
      />
      <Row>
        <Col>
          <FormInputColor
            name="popUpBtnTextColor"
            type="text"
            label="Button text color"
          />
        </Col>
        <Col>
          <FormInputColor
            name="popUpBtnBackgroundColor"
            type="text"
            label="Button background color"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInputColor
            name="popUpBtnBorderColor"
            type="text"
            label="Button border color"
          />
        </Col>
        <Col>
          <FormInput
            name="popUpBtnBorderRadius"
            type="number"
            label="Button border radius, px"
            min={0}
            max={100}
          />
        </Col>
      </Row>
    </Card>
  )
}
export default PopUpSettings