import React, {useEffect, useState} from 'react';
import {Loader} from "pbc-apps-components";
import style from "./Rule.module.scss";
import getApi from "../Helpers/Api";
import {useParams} from "react-router-dom";
import NewRuleFirstStep from "../Components/NewRuleFirstStep/NewRuleFirstStep";
import RuleForm from "../Components/RuleForm/RuleForm";

let Rules = () => {
  const [ruleConfig, setRulesConfig] = useState({})
  const [progressPending, setProgressPending] = useState(true)
  const [locationPathNew, setLocationPathNew] = useState(false)
  const params = useParams()

  function setRules () {
    if (location && location.pathname === '/rules-list/new') {
      setLocationPathNew(true)
      setProgressPending(false)
    } else if (params.hasOwnProperty('ruleSK')) {
      getApi().getRule(window.atob(params.ruleSK)).then(response => {
        return response.data
      }).then(response => {
        setRulesConfig(response.data.Item)
        setProgressPending(false)
      })
    }
  }

  useEffect(() => {
    setRules()
  }, [])

  return (
    <React.Fragment>
      {
        progressPending ?
          <div className={style.loaderWrapper}>
            <Loader height="xl"/>
          </div>
          :
          <div>
            {locationPathNew ?
              <NewRuleFirstStep/>
              :
              <RuleForm
              ruleConfig={ruleConfig}
              />
            }
          </div>
      }
    </React.Fragment>
  );
}

export default Rules;
