import style from "./PreviewWidget.module.scss";
import React from "react";

const PreviewCondition = ({formMethods}) => {
  let ruleType = ''

  switch (formMethods.watch('ruleType')) {
    case 'basedCartAmount':
      let upperLimitString= formMethods.watch("condition.upperLimit") ? (<>and less then <span className={style.bold}>{formMethods.watch('condition.max')}</span></>) : ''
      ruleType = <>When total items in cart more then <span className={style.bold}>{formMethods.watch('condition.min')}</span> {upperLimitString}</>
      break
    case 'basedCartItemsCount':
      let upperLimitText = formMethods.watch("condition.upperLimit") ? (<>and less then <span className={style.bold}>{formMethods.watch('condition.max')}</span></>) : ''
      ruleType = <>When total items in cart more then <span className={style.bold}>{formMethods.watch('condition.min')}</span> {upperLimitText}</>
      break
    case 'basedSpecificProducts':
      ruleType = <>When <span className={style.bold}>{formMethods.watch("condition.products[0].title")}</span> is added to cart</>
      break
    case 'basedProductCombinations':
      ruleType =
        <>
          <p className='mb2'>When these products are added to cart:</p>
          <ul>
            {formMethods.watch("condition.products")?.map(product => {
              return <li className={style.listItem} key={product.id}>
                {
                  product.variantTitle !== '' ?
                    <>
                      -
                      <span className={style.bold}>{product.variantTitle}</span>
                    </>
                    :
                    <>
                      -
                      <span className={style.bold}>{product.title}</span>
                    </>
                }
              </li>
            })}
          </ul>
        </>
      break
  }

  return (
    <div className={style.blockBox}>
      <p className={style.title}>Condition:</p>
      <div>
        {ruleType}
      </div>
    </div>
  )
}

export default PreviewCondition