import {BrowserRouter, Route, Routes as Switch} from "react-router-dom";
import menuItems from "./menu-items";
import {NavigationMenu} from "@shopify/app-bridge-react";
import Dashboard from "./Pages/Dashboard";
import Rule from "./Pages/Rule";

const Routes = () => {

  let links = menuItems.map(item => {
    return {
      label: item.title,
      destination: item.url
    }
  })

  return (
    <BrowserRouter>
      <NavigationMenu
        navigationLinks={links}
      />
      <Switch>
        <Route path={"/rules-list/:ruleSK"} element={<Rule />}/>
        <Route path={"/"} element={<Dashboard />}>Dashboard</Route>
        {
          menuItems.map((item, index) => <Route key={index} path={item.url} element={item.component}/>)
        }
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
