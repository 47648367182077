import {Card, FormCheckbox, FormInput, FormSelect} from "pbc-apps-components";
import React from "react";

const PricingRule = () => {
  return (
    <Card cardTitle={"Pricing rules"} autoHeight={true}>
      <FormSelect
        label="Discount type"
        name="discountType"
        options={[
          {value: 'percent', label: "Percentage"},
          {value: 'fix', label: "Fixed discount"}
        ]}
      />
      <FormInput
        name="discountAmount"
        type="number"
        label="Gift product discount amount, %"
        min={1}
        max={1000000}
      />
      <FormCheckbox
        name={'freeShipping'}
        label={'Enable free shipping'}
        disabled={false}
        value={true}
      />
    </Card>
  )
}
export default PricingRule