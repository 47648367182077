import React, {useState} from 'react';
import {Form, FormInput, FormTextarea, Header} from "pbc-apps-components";
import {Col, Container, Row} from "react-grid-system";
import * as yup from "yup";
import getApi from "../Helpers/Api";
import helpBanner from "../assets/images/help_banner.png";
import styles from "./Help.module.scss";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast, ToastContainer} from "react-toastify";

let Help = () => {
  const [savingProcess, setSavingProcess] = useState(false);

  const showNotification = (type, text) => {
    toast[type](text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const handleSubmit = (data) => {
    setSavingProcess(true);
    return getApi().sendHelpRequest(data).then(() => {
      showNotification('success', 'Your request has been sent!');
      setSavingProcess(false);
    });
  }


  const validationRules = yup.object().shape({
    subject: yup.string().required("⚠️ This field is required!"),
    body: yup.string().required("⚠️ This field is required!").test('len', "⚠️ Message should have more than 20 symbols", val => val.toString().length > 20)
  })

  const formMethods = useForm({
    resolver: yupResolver(validationRules),
    reValidateMode: "onSubmit",
  });

  return (
    <div className="pageContent">
      <Header pageTitle="Help"/>
      <Container>
        <Row>
          <Col offset={{xl: 2}} xl={8} lg={12}>
            <div className={styles.helpCardWrapper}>
              <div className={styles.helpCardContent}>
                <div className={styles.helpCardBanner}>
                  <img src={helpBanner} alt="PBC Help"/>
                </div>
                <div className={styles.helpCardDescription}>
                  <p>
                    We're committed to improving our app and ensuring your satisfaction. With 24/7 availability
                    and guaranteed email replies within 12 hours, our Support team is here to help. Whether you
                    have installation issues, feature requests, or just want to say hi, feel free to reach out
                    via the form or <span onClick={() => {window.tidioChatApi.open()}}>live chat</span>.
                  </p>
                </div>
              </div>
              <div className={styles.helpCardForm}>
                <h3 className={styles.formTitle}>Help Request Form</h3>
                <Form
                  submitLabel="Send"
                  onSubmit={handleSubmit}
                  formMethods={formMethods}
                  savingProcess={savingProcess}
                  customSubmit={false}
                  id="help">
                  <FormInput
                    name="subject"
                    type="text"
                    label="Subject"
                    onKeyDown={() => formMethods.clearErrors('subject')}
                  />
                  <FormTextarea
                    name="body"
                    label="How can we help you? (please indicate your question here)"
                    rows={9}
                    onKeyDown={() => formMethods.clearErrors('body')}
                  />
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </div>
  );
}

export default Help;