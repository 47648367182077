import {Col, Row} from "react-grid-system";
import React, {useState} from "react";
import {Button} from "pbc-apps-components";
import style from "./ProductItem.module.scss";

const ProductItem = ({formMethods, product, products, productsRoute}) => {
  const [deleteProcess, setDeleteProcess] = useState(false)
  const handleDelete = (productId) => {
    setDeleteProcess(true)
    const productIndex = products.findIndex(product => product.id === productId)
    products.splice(productIndex, 1)
    formMethods.setValue(productsRoute, products, {shouldDirty: true})
    setDeleteProcess(false)
  }

  return (
    <div className={style.box}>
      <Col lg={1} xs={3} style={{padding: 0}}>
        <img src={product.image} alt={product.title} width={'100%'}/>
      </Col>
      <Col lg={9} xs={3} style={{padding: 0}}>
        <div className={style.titleBox}>
          <h3>{product.title}</h3>
          {product.variantTitle !== '' ?
            <p>{product.variantTitle}</p>
            : null
          }
        </div>
      </Col>
      <Col lg={2} xs={3} style={{padding: 0}}>
        <div style={{marginLeft:"auto", width:'fit-content'}}>
          <Button
            content="Remove"
            variant="btnWhite"
            buttonTextColor="#f46a6a"
            isLoading={deleteProcess}
            onClick={() => handleDelete()}
          />
        </div>
      </Col>
    </div>
  )
}
export default ProductItem